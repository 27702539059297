import axiosClient from '../apiClient';
import dayjs from 'dayjs';

export function getCallsStats(timeSlot) {
    const params = new URLSearchParams();
    if (timeSlot) {
        params.append('time_slot', timeSlot);
    }
    return axiosClient.get(`/clinic/calls-stats/?action=count_calls&${params.toString()}`);
}

export function getAverageCallLength(timeSlot) {
    return axiosClient.get(`/clinic/calls-stats?action=avg_call_length&time_slot=${timeSlot}`);
}

export function getAverageCallLengthByUser(user_id) {
    const timeSlot = '2023-01-01,' + dayjs().format('YYYY-MM-DD');
    return axiosClient.get(
        `/clinic/calls-stats/?action=avg_call_length&time_slot=${timeSlot}&filter[user_id]=${user_id}`
    );
}

export function getCallsAll(timeSlot) {
    return axiosClient.get(`/clinic/calls-stats/?action=total_stats&time_slot=${timeSlot}`);
}

export function getCallsByStatus(status, timeSlot) {
    return axiosClient.get(`/clinic/calls-stats/?action=count_calls&time_slot=${timeSlot}&filter[status]=${status}`);
}

export function getCallsByQueues(timeSlot, queue, status) {
    return axiosClient.get(
        `/clinic/calls-stats/?action=count_calls&time_slot=${timeSlot}&filter[status]=${status}&filter[voip_queue_id]=${queue}`
    );
}

export function getCallsByUser(action, timeSlot, user, status, type) {
    const params = new URLSearchParams();
    if (action) {
        params.append('action', action);
    }
    if (timeSlot) {
        params.append('time_slot', timeSlot);
    }
    if (status) {
        params.append('filter[status]', status);
    }
    if (user) {
        params.append('filter[user_id]', user);
    }
    if (type) {
        params.append('filter[type]', type);
    }
    return axiosClient.get(`/clinic/calls-stats/?${params.toString()}`);
}

export function getHeatmap(timeSlot) {
    return axiosClient.get(`/clinic/calls-stats/?action=heatmap&time_slot=${timeSlot}`);
}

export function getAllCallsToCallBack(timeSlot) {
    return axiosClient.get(
        `/clinic/calls-stats/?action=count_calls&time_slot=${timeSlot}&filter[called_back]=0&filter[status]=not_answered`
    );
}

export function getAllCalledBack(timeSlot, user) {
    const params = new URLSearchParams();
    params.append('filter[called_back]', 1);
    params.append('filter[type]', 'outgoing');
    if (timeSlot) {
        params.append('time_slot', timeSlot);
    }
    if (user) {
        params.append('filter[user_id]', user);
    }
    return axiosClient.get(`/clinic/calls-stats/?action=count_calls&${params.toString()}`);
}

export function getAllCalledBackByHours(hours, timeSlot) {
    return axiosClient.get(
        `/clinic/calls-stats/?action=count_called_back_by_hours&time_slot=${timeSlot}&filter[hours]=${hours}`
    );
}

export function getAverageCalledBackTime(timeSlot) {
    return axiosClient.get(`/clinic/calls-stats/?action=avg_called_back_time&time_slot=${timeSlot}`);
}
