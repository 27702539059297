<template>
    <edit-popup
        :id="formCallId"
        :callback="sendForm"
        :fields="{
            ...fields,
            phones: {
                phone_list:
                    this.fields?.phones && this.fields?.phones.length
                        ? this.fields?.phones
                        : [
                              {
                                  isMain: 1,
                                  phone: null,
                              },
                          ],
            },
        }"
        :force-active="forceActive"
        :mode="mode"
        :pencil="'pencil'"
        :show-icon="showIcon"
        :title="isEdit ? 'Edytuj pacjenta' : 'Dodaj pacjenta'"
        outer-class="sm:max-w-6xl"
    >
        <template v-slot="slotProps">
            <FormKit
                :id="formCallId"
                ref="form"
                #default="{ value, state }"
                :actions="false"
                :incomplete-message="false"
                :value="deafultUserData"
                form-class="form"
                submit-label="Zapisz"
                type="form"
                @submit="
                    (data) => {
                        if (sendForm(data)) slotProps.closePopup();
                    }
                "
            >
                <div class="grid gap-5 sm:grid-cols-2 lg:grid-cols-3">
                    <!-- NAME -->
                    <FormKit
                        error-behavior="submit"
                        name="name"
                        outer-class="input-text"
                        placeholder="Imię"
                        type="text"
                        validation="required"
                        validation-label="Imię"
                    ></FormKit>

                    <!-- SURNAME -->
                    <FormKit
                        error-behavior="submit"
                        name="surname"
                        outer-class="input-text"
                        placeholder="Nazwisko"
                        type="text"
                        validation="required"
                        validation-label="Nazwisko"
                    ></FormKit>
                </div>
                <FormKit name="phones" type="group">
                    <div class="flex flex-col gap-3">
                        <FormKit
                            v-model="phones"
                            #default="{ items, node, value }"
                            dynamic
                            name="phone_list"
                            type="list"
                        >
                            <FormKit v-for="(item, index) in items" :key="item" :index="index" type="group">
                                <div class="flex gap-6">
                                    <FormKit
                                        :validation-messages="{
                                            validatePhoneOrEmail: requireMessage('numer telefonu'),
                                            validatePhone: phoneValidationMessages('isValidPhone'),
                                        }"
                                        :validation-rules="{ validatePhone, validatePhoneOrEmail }"
                                        name="phone"
                                        placeholder="Numer telefonu"
                                        type="text"
                                        validation="validatePhone|+*validatePhoneOrEmail"
                                        validation-name="Pole numer telefonu"
                                    />
                                    <FormKit :value="mainPhone === index" name="isMain" type="hidden"> </FormKit>
                                    <!--                            TODO after merge to develop delete isMobile and type-->
                                    <FormKit :value="true" name="isMobile" type="hidden"> </FormKit>
                                    <FormKit name="type" type="hidden" value="home"> </FormKit>
                                    <div class="flex items-center">
                                        <div>
                                            <button
                                                class="flex gap-2 items-center font-light"
                                                type="button"
                                                @click="mainPhone = index"
                                            >
                                                <div class="checkbox">
                                                    <label v-show="mainPhone === index"></label>
                                                </div>
                                                główny
                                            </button>
                                        </div>
                                    </div>
                                    <button
                                        class="button button--second-red"
                                        type="button"
                                        @click="() => node.input(value.filter((_, i) => i !== index))"
                                    >
                                        Usuń
                                    </button>
                                </div>
                            </FormKit>

                            <button
                                class="button button--second-green max-w-max"
                                type="button"
                                @click="() => node.input(value.concat({}))"
                            >
                                Dodaj numer
                            </button>
                        </FormKit>
                    </div>
                </FormKit>

                <div class="grid gap-5 sm:grid-cols-2 lg:grid-cols-3 items-center">
                    <!-- EMAIL -->
                    <FormKit
                        :validation-messages="{
                            validatePhoneOrEmail: requireMessage('email'),
                            validateEmail: 'Niepoprawny format emaila',
                        }"
                        :validation-rules="{ validateEmail, validatePhoneOrEmail }"
                        name="email"
                        outer-class="input-email"
                        placeholder="Pole adres e-mail"
                        type="text"
                        validation="validateEmail|+*validatePhoneOrEmail|+*validateGender"
                        validation-name="Pole adres e-mail"
                    ></FormKit>
                    <div>
                        <FormKit
                            v-model="pesel"
                            :validation-messages="{
                                validatePesel: 'Numer PESEL powinien zawierać 11 cyfr, np. 12345678901',
                            }"
                            :validation-rules="{
                                validatePesel,
                            }"
                            name="pesel"
                            outer-class="input-text"
                            placeholder="PESEL"
                            type="number"
                            validation="validatePesel"
                        >
                        </FormKit>
                    </div>
                    <div v-show="slotProps.formData?.pesel || pesel">
                        <div
                            class="button button--second-red"
                            @click="
                                () => {
                                    slotProps.formData.pesel = '';
                                    pesel = '';
                                }
                            "
                        >
                            Usuń
                        </div>
                    </div>
                </div>

                <div class="grid gap-5 sm:grid-cols-2 lg:grid-cols-3">
                    <!-- GENDER -->
                    <custom-select
                        :default-value="slotProps.formData && slotProps.formData.gender"
                        :options="genderOptions"
                        :validation-messages="{ validateGender: requireMessage('płeć') }"
                        :validation-rules="{ validateGender }"
                        name="gender"
                        outer-class="w-full"
                        placeholder="Wybierz płeć"
                        validation="+validateGender"
                        validation-name="Pole płeć"
                    ></custom-select>
                    <!-- SOURCE -->
                    <custom-select
                        :default-value="slotProps.formData && slotProps.formData.source?.id"
                        :options="mappedOptionsSources"
                        name="patientSourceId"
                        outer-class="w-full"
                        placeholder="Skąd przyszedł?"
                        validation-name="Skąd przyszedł?"
                        @update-form="
                            (data) => {
                                patientSource = data.value;
                                slotProps.change(data);
                            }
                        "
                    ></custom-select>
                    <!-- INSTAGRAM NICK -->
                    <FormKit
                        v-show="slotProps.formData && slotProps.formData.patientSourceId === getInstagramId"
                        error-behavior="submit"
                        name="nickInsta"
                        outer-class="input-text"
                        placeholder="Nick Instagram"
                        type="text"
                        validation-name="Instagram"
                    ></FormKit>
                </div>
                <!-- LANGUAGE -->
                <div class="grid gap-5 grid-cols-3">
                    <custom-select
                        :default-value="(slotProps.formData && slotProps.formData.languageId) || '1'"
                        :options="langOptions"
                        :validation-messages="{
                            isEmailAndLanguage: requireMessage('język kontaktu'),
                        }"
                        :validation-rules="{
                            isEmailAndLanguage: validateLanguage,
                        }"
                        :value="(slotProps.formData && slotProps.formData.languageId) || 1"
                        class="w-full"
                        name="languageId"
                        placeholder="Język kontaktu:"
                        validation="isEmailAndLanguage"
                        validation-name="Język pacjenta"
                        @update-form="
                            (data) => {
                                // patientSource = data.value;
                                slotProps.change(data);
                            }
                        "
                    ></custom-select>
                </div>

                <div>
                    <span class="font-light block mb-2">Pola dodatkowe:</span>
                    <div class="grid gap-5 sm:grid-cols-2 lg:grid-cols-3">
                        <!-- ADDITIONAL FIELDS -->
                        <FormKit
                            v-if="slotProps.formData"
                            v-model="metafields"
                            name="metafields"
                            outer-class="absolute"
                            type="hidden"
                        >
                        </FormKit>

                        <custom-select
                            v-for="(field, index) in addsFields"
                            :key="index"
                            :additional-field="true"
                            :default-value="mode === 'edit' ? defaultValue(field) : null"
                            :metafield-id="field.id"
                            :name="field.key"
                            :options="mappedFields(field.selectableValues)"
                            :placeholder="`Wybierz ${field.label}`"
                            class="w-full"
                            @update-form="metafieldsData"
                        >
                        </custom-select>
                    </div>
                </div>

                <FormKit
                    error-behavior="submit"
                    name="notes"
                    outer-class="input-textarea"
                    placeholder="Dodatkowe informacje"
                    type="textarea"
                />
            </FormKit>
            <div class="flex justify-end">
                <FormKit
                    v-if="mode === 'edit'"
                    :disabled="isLoading"
                    :label="isLoading ? '...' : 'Zapisz'"
                    outer-class="input-submit input-submit--filled"
                    type="button"
                    @click="submitForm"
                />

                <FormKit
                    v-else-if="mode === 'create'"
                    :disabled="isLoading"
                    :label="isLoading ? '...' : 'Dodaj pacjenta'"
                    outer-class=" button button--main-green"
                    type="button"
                    @click="submitForm"
                />
            </div>
        </template>
    </edit-popup>
</template>

<script>
    import DetailsForm from '@/components/v1/Patient/DetailsForm';
    import EditPopup from '@/components/v2/EditPopup';
    import CustomSelect from '@/elements/v2/form/CustomSelect';
    import CustomIcon from '@/elements/v2/CustomIcon';
    import { isValidEmail, isValidPeselNumber, isValidPhoneNumber } from '@/lib/regex';
    import { handleErrors, handleSuccess } from '@/lib/notifications';
    import { mapActions, mapGetters } from 'vuex';
    import { notify } from '@kyvg/vue3-notification';
    import { callStatus } from '@/lib/constants';

    export default {
        mixins: DetailsForm.mixins,
        components: {
            EditPopup,
            CustomSelect,
            CustomIcon,
        },
        props: {
            formId: [String, Number],
            fields: {
                type: Object,
                require: false,
                default: () => ({
                    name: '',
                    surname: '',
                    email: '',
                    pesel: null,
                    source: null,
                    languageId: 1,
                    gender: null,
                    nickInsta: null,
                    notes: null,
                    phones: [
                        {
                            phone: null,
                            isMain: 1,
                        },
                    ],
                }),
                validator: function (value) {
                    if (Array.isArray(value) && value?.length === 0) {
                        return false;
                    }
                    return true;
                },
                forceActive: Boolean,
            },
            showIcon: {
                type: Boolean,
                default: true,
            },
        },
        computed: {
            ...mapGetters('dicts', ['additionalFields', 'patientSources']),
            addsFields() {
                return this.additionalFields
                    ? this.additionalFields?.filter((field) => field.modelType === 'user')
                    : null;
            },
            mappedOptionsSources() {
                return this.patientSources
                    ? this.patientSources.map((source) => ({
                          label: source.name,
                          value: source.id,
                          id: source.id,
                      }))
                    : [];
            },
            deafultUserData() {
                return {
                    ...this.fields,
                    metafields: this.metafields,
                    phones: {
                        phone_list:
                            this.fields?.phones && this.fields?.phones.length
                                ? this.fields?.phones
                                : [
                                      {
                                          isMain: 1,
                                          phone: null,
                                      },
                                  ],
                    },
                };
            },
            formCallId() {
                return this.formId ? `patient-${this.mode}-${this.formId}` : `patient-${this.mode}`;
            },
            getInstagramId() {
                return this.patientSources
                    ? this.patientSources.filter((source) => source.slug === 'instagram')[0].id
                    : null;
            },
            path() {
                return this.$route.path;
            },
        },
        async created() {
            this.pesel = this.fields.pesel;
            const data = this.additionalFields;
            if (!data) {
                await this.fetchDictionaries();
            }
            if (this.fields.metafields) {
                this.metafields = this.fields.metafields.map((field) => ({
                    metafield_id: +field.metafieldId,
                    metafield_selectable_value_id: +field.metafieldSelectableValueId,
                }));
            }
        },

        data: () => ({
            pesel: null,
            metafields: [],
            phones: [
                {
                    phone: null,
                    isMain: 1,
                },
            ],
            required: true,
            formData: null,
        }),

        methods: {
            ...mapActions('dicts', ['fetchDictionaries']),
            ...mapActions('calls', ['getCalls']),
            defaultValue(field) {
                const findEl = this.metafields?.find((slectedField) =>
                    field.selectableValues.find((el) => +el.id === +slectedField.metafield_selectable_value_id)
                );
                return findEl?.metafield_selectable_value_id;
            },
            metafieldsData(field) {
                if (field.metafield_selectable_value_id === 0) return;
                const data = this.metafields?.filter((el) => +el.metafield_id === +field.metafield_id);
                const fields = this.metafields;
                if (data.length === 0) {
                    this.metafields = [...this.metafields, field];
                } else if (data.length > 0) {
                    this.metafields = fields.map((item) =>
                        +item.metafield_id === +field.metafield_id
                            ? {
                                  ...item,
                                  metafield_selectable_value_id: field.metafield_selectable_value_id,
                              }
                            : item
                    );
                }
            },
            mappedFields(field) {
                return field.map((field) => ({
                    label: field.value,
                    value: field.id,
                    __original: field.id,
                    id: field.id,
                }));
            },
            validateLanguage(e) {
                return true;
            },
            validatePesel(node) {
                const pesel = node.value;
                return pesel ? isValidPeselNumber(pesel) : true;
            },

            validatePhone(node) {
                const phone = node.value;
                return phone ? isValidPhoneNumber(phone) : true;
            },

            validateEmail(node) {
                const email = node.value;
                return email ? isValidEmail(email) : true;
            },
            validateGender(node) {
                const parent = node.at('$parent');
                if (parent.value) {
                    for (const childName in parent.value) {
                        if (childName === 'gender') {
                            if (parent.value['email'] && !node.value) {
                                return false;
                            }
                        }
                    }
                }
                return true;
            },

            requireMessage(val) {
                return `Pole ${val} jest wymagane`;
            },
            phoneValidationMessages(type) {
                if (type === 'isValidPhone')
                    return 'Numer telefonu powinien zawierać 6-14 cyfr i zaczynać się od numeru kierunkowego np. +48111222333';
            },
            validatePhoneOrEmail(node) {
                let parent;
                if (node.name === 'phone') {
                    parent = node.at('$parent').at('$parent').at('$parent').at('$parent');
                } else {
                    parent = node.at('$parent');
                }

                if (parent.value) {
                    for (const childName in parent.value) {
                        if (parent.value['phones'].phone_list.some(({ phone }) => !phone) && !parent.value['email']) {
                            return false;
                        }
                    }
                }
            },
            async submitForm() {
                await this.$refs.form.node.submit(`patient-${this.mode}`);
            },
            async sendForm(rawData) {
                const isEditMode = this.mode === 'edit';
                const action = isEditMode ? this.editPatient : this.createPatient;

                let data = rawData;
                data.phones = data.phones.phone_list;

                data.phones = data.phones
                    ?.filter(({ phone }) => phone)
                    .map((phone, i) => ({
                        ...phone,
                        isMain: i === this.mainPhone ? 1 : 0,
                        isMobile: +phone.isMobile,
                    }));
                if (data.phones.length && !data.phones.some((phone) => phone.isMain)) {
                    notify({
                        group: 'general',
                        type: 'error',
                        text: 'Przynajmniej jeden numer telefonu musi być główny',
                    });
                    return;
                }
                data = this.removeFields(data);

                try {
                    await action({
                        data: this.removeNullValues(data),
                        id: data.id,
                    });
                    handleSuccess(this.translate(isEditMode ? 'updated' : 'created', 'store', 'client'));
                    if (this.mode === 'create') {
                        this.$formkit.reset(this.formCallId);
                    }
                } catch (e) {
                    handleErrors(e);
                }
            },
            removeFields(data) {
                const fieldsNames = this.additionalFields.map((field) => field.key);
                return Object.fromEntries(Object.entries(data).filter((value) => !fieldsNames.includes(value[0])));
            },
            removeNullValues(obj) {
                return Object.fromEntries(
                    Object.entries(obj).filter(
                        (value) => value[1] !== null && value[1] !== '' && value[1]?.length !== 0
                    )
                );
            },
        },
        watch: {
            fields(fields, newFields) {
                this.pesel = fields.pesel;
            },
        },
    };
</script>

<style>
    .formkit-suffix-icon {
        appearance: none;
        background: none;
        border: none;
        font-size: 1em;
    }
</style>
